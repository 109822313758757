import React from 'react';
import {message, Button, Table} from 'antd';
import styled from 'styled-components';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import withPageEntry from '../../withPageEntry';
import {getUserStateLabel, getUserSubstateLabel} from '../../Utils/UserUtil';

async function generateExcelFile(name, headers, records) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([headers, ...records]);

    window.XLSX.utils.book_append_sheet(wb, ws, name);

    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });

    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );

    await AppActions.delay(600);
    message.success('成功創建下載連結');

    return objUrl;
  } catch (err) {
    console.warn(err);
  } finally {
    AppActions.setLoading(false);
  }
}

function VideoProcess(props) {
  const params = qs.parse(props.location.search);
  const {action, product: pid, signform} = params;
  const [product, setProduct] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [url, setUrl] = React.useState('');

  const fetchProductById = React.useCallback(async (id) => {
    AppActions.setLoading(true);
    try {
      setProduct(await JStorage.fetchOneDocument('product', {id}));
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  const fetchRecordsByProductId = React.useCallback(async (id) => {
    AppActions.setLoading(true);
    try {
      let nextRecords = [];
      const records = await JStorage.fetchAllDocuments(
        'video_attend',
        {
          product_id: id,
        },
        ['-created'],
      );
      if (records.length > 0) {
        const users = await JStorage.fetchAllDocuments(
          'user_profile',
          {
            owner: {$in: records.map((it) => it.user_id)},
          },
          null,
          {owner: 1, name: 1, state: 1, substate: 1, id_card_number: 1},
        );

        nextRecords = [...records].map((it) => {
          const user = users.find((user) => user.owner === it.user_id);
          return {...it, ...user};
        });
      }

      setRecords(nextRecords);
    } catch (err) {
      console.warn(err);
      message.error('發生錯誤');
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (!product) {
      fetchProductById(pid);
      fetchRecordsByProductId(pid);
    }
  }, [fetchProductById, fetchRecordsByProductId, pid, product]);

  if (!product) {
    return null;
  }

  return (
    <Wrapper>
      <h2>{product.name}</h2>
      <div className="container">
        <Button
          onClick={async () => {
            const url = await generateExcelFile(
              `影片達標紀錄`,
              [
                '流水號',
                '身分證字號',
                '姓名',
                '狀態',
                '次狀態',
                '達標創建時間',
              ],
              records.map((it, index) => [
                `${index + 1}`,
                it.id_card_number,
                it.name,
                getUserStateLabel(it.state),
                getUserSubstateLabel(it.substate),
                `${new Date(it.created).toLocaleString('sv')}`,
              ]),
            );
            setUrl(url);
          }}>
          匯出
        </Button>
        {url && (
          <Button
            type="link"
            href={url}
            download={`${product.name}影片達標紀錄.xlsx`}>
            下載
          </Button>
        )}
      </div>
      <div className="container">
        <Table
          dataSource={records}
          pagination={{
            showTotal: (total) => `共 ${total} 筆`,
          }}
          columns={[
            {
              key: 'index',
              title: '流水號',
              dataIndex: 'index',
              render: (value, record, index) => index + 1,
            },
            {
              key: 'id_card_number',
              title: '身分證字號',
              dataIndex: 'id_card_number',
            },
            {
              key: 'name',
              title: '姓名',
              dataIndex: 'name',
            },
            {
              key: 'state',
              title: '狀態',
              dataIndex: 'state',
              render: (value, record, index) => getUserStateLabel(value),
            },
            {
              key: 'substate',
              title: '次狀態',
              dataIndex: 'substate',
              render: (value, record, index) => getUserSubstateLabel(value),
            },
            {
              key: 'created',
              title: '達標創建時間',
              dataIndex: 'created',
              render: (value, record, index) =>
                new Date(value).toLocaleString('sv'),
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > .container {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export default withPageEntry(VideoProcess);
